<template>
  <div class="portfolio-container">
    <h1>Here are my recent projects</h1>
    <div class="project-container">
      <div class="project" v-for="project in projects" :key="project.id">
        <div class="title" @click="openInNewTab(project.projectUrl)">
          {{ project.title }}
        </div>
        <div @click="openInNewTab(project.projectUrl)" class="image-container">
          <img :src="project.thumbnailUrl" />
        </div>
        <div class="framework-container">
          <div class="framework-icon" v-if="project.react == 'yes'">
            <img src="@/assets/icons/react.png" alt="React" />
          </div>
          <div class="framework-icon" v-if="project.vue == 'yes'">
            <img src="@/assets/icons/vue.png" alt="Vue" />
          </div>
          <div class="framework-icon" v-if="project.firebase == 'yes'">
            <img src="@/assets/icons/firebase.png" alt="Firebase" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['projects'],
  setup() {
    const openInNewTab = (url) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    };
    return { openInNewTab };
  },
};
</script>

<style scoped>
.portfolio-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding-bottom: 4rem;
}
h1 {
  font-size: 2rem;
  margin: 2rem 0;
}
.project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.project {
  width: 400px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1rem;
  border-radius: 25px;
  padding: 1rem;
  background-color: var(--background);
}

.project .image-container {
  width: 350px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0;
  border-radius: 25px;
  -webkit-box-shadow: 0px 0px 13px 1px var(--accent);
  box-shadow: 0px 0px 13px 1px var(--accent);
}

.project img {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  border-radius: 25px;
  cursor: pointer;
  margin: 0;
}
.title {
  font-size: 1.5rem;
  cursor: pointer;
}
.framework-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.framework-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  height: 60px;
  width: 60px;
}
.framework-icon img {
  margin: 0;
  width: 100%;
  cursor: auto;
}

/* Media Styles */
@media (max-width: 768px) {
  .project-container {
    flex-direction: column;
    align-items: center;
  }
  .project {
    width: 80%;
    margin-bottom: 3rem;
  }
  .framework-icon {
    height: 40px;
    width: 40px;
  }
  h1 {
    font-size: 1.5rem;
  }
  .title {
    font-size: 1.25rem;
  }
  img {
    width: 100%;
    height: auto;
  }
}
</style>
