<template>
  <div class="home">
    <div class="hero-container">
      <h1>Frontend Web Developer</h1>
      <p>
        I create simple sophisticated designs and code, and I love what I do.
      </p>
      <img src="@/assets/hero.png" alt="" />
    </div>
    <div class="introduction-container">
      <div class="introduction">
        <h2>Hi, I'm Matthias. Nice to meet you.</h2>
        <p>
          Nothing has excited me more than the idea of creating something new
          and web development has given me the opportunity to do just that. I am
          always working on improving my chops and learning new technologies.
          With a focus on the frontend, I have experience with React, Vue,
          Firebase, Node Express and Mongo.
        </p>
      </div>
    </div>
    <Portfolio :projects="projects" />
  </div>
</template>

<script>
import getCollection from "@/composables/getCollection";
import Portfolio from "@/components/portfolio/Portfolio.vue";
import { ref } from "vue";
export default {
  components: {
    Portfolio,
  },
  name: "HomeView",

  setup() {
    const { documents: projects } = getCollection("projects");

    return { projects };
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
p {
  margin-top: 1rem;
  font-size: 1.5rem !important;
}
.hero-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 3rem;
}

img {
  height: 234px;
  width: 234px;
  margin-top: 3rem;
}

.introduction-container {
  width: 100vw;
  padding: 3rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.introduction-container h2 {
  font-size: 2rem !important;
}
.introduction-container p {
  font-size: 1.25rem !important;
}
.introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
}
.introduction p {
  text-align: left;
}

/* Media Styles */
@media (max-width: 768px) {
  img {
    height: 150px;
    width: 150px;
  }
  .hero-container {
    padding: 2rem 2rem;
    text-align: center;
  }
  h1 {
    font-size: 2rem !important;
  }
  .introduction p {
    font-size: 1.25rem !important;
    padding: 1rem;
    text-align: center;
  }
}
</style>
