<template>
  <div class="navbar">
    <nav>
      <img src="@/assets/logo.png" alt="Logo" />
      <h1>
        <router-link to="/">Mattwf</router-link>
      </h1>
      <div class="links">
        <button @click="handleClick" v-if="currentRouteName == 'Home'">
          Say Hello
        </button>
        <i
          class="material-icons"
          @click="handleClick"
          v-if="currentRouteName == 'Contact'"
          >arrow_back</i
        >
      </div>
    </nav>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const currentRouteName = computed(() => route.name);

    const handleClick = () => {
      if (currentRouteName.value == "Home") {
        router.push({ name: "Contact" });
      } else {
        router.push({ name: "Home" });
      }
    };

    return { currentRouteName, handleClick };
  },
};
</script>

<style scoped>
.navbar {
  padding: 16px 10px;
  margin-bottom: 60px;
}
i {
  margin: 8px;
  margin-right: 40px;
  font-size: 2rem;
  cursor: pointer;
  color: var(--primary);
}
i:hover {
  color: var(--secondary);
}
nav {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  color: var(--text);
}

nav h1 {
  margin-left: 20px;
  text-decoration: none;
}

nav .links {
  margin-left: auto;
}
nav img {
  max-height: 60px;
}
nav button {
  border-radius: 9999px;
  padding-left: calc(1em + 0.25em);
  padding-right: calc(1em + 0.25em);
  border: 1px solid var(--secondary);
  border-width: 2px;
  font-weight: 400;
  height: auto;
  width: 150px;
  background-color: var(--background);
  color: var(--text);
  padding: 9px 25px;
  cursor: pointer;
}
nav button:hover {
  background: var(--secondary);
  border: 1px solid var(--secondary);
  color: white;
}

/* Media Styles */
@media (max-width: 768px) {
  nav img {
    max-height: 40px;
  }
  nav h1 {
    font-size: 1.5rem;
  }
  nav button {
    padding: 9px 15px;
  }
}
</style>
