import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBHXiVWZPIwcWtVY-_QNVWNRh0evj8B7sU",
  authDomain: "mattwf.firebaseapp.com",
  projectId: "mattwf",
  storageBucket: "mattwf.appspot.com",
  messagingSenderId: "434264318770",
  appId: "1:434264318770:web:1fd912db97881a92ab2d2b",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//Initialize Services
const projectFirestore = firebase.firestore();

//Timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectFirestore, timestamp };
